import { get, post } from './ajaxutils'
import searchhelper from '../searchhelper'

export default {
  dontRateApp (action) {
    return post('/api/dontrateapp', {
      params: JSON.stringify({ action })
    }).then((response) => response.data)
  },
  listBanks () {
    return get('/api/list_banks').then((response) => response.data)
  },
  groups (limit) {
    return get('/api/groups', { limit: limit }).then(
      (response) => response.data
    )
  },
  trechos (limit) {
    return get('/api/trechos', { limit: limit }).then(
      (response) => response.data
    )
  },
  trechosGratis (limit) {
    return get('/api/trechos_gratis', { limit: limit }).then(
      (response) => response.data
    )
  },
  promocao (code, validate) {
    validate = validate || false
    const params = validate ? '?validate=true' : ''
    return get(`/api/promo/${code}${params}`).then((response) => response.data)
  },
  searchPromoGroups ({ code, origemSlug, destinoSlug, departureDate }) {
    const params = { code, origemSlug, destinoSlug, departureDate }
    return get('/api/promogroups', params).then((response) =>
      searchhelper.parsePromoSearchResponse(response.data)
    )
  },
  checaSeGanhouSddsDeVc () {
    return get('/api/checaseganhousddsdevc').then((response) => response.data)
  },
  consultacep (cep) {
    return get(`/api/consultacep/${cep}`).then((response) => response.data)
  },
  group (idIda, idVolta) {
    const params = { idIda }
    if (idVolta) {
      params.idVolta = idVolta
    }
    return get('/api/groups/get', params).then((response) => response.data)
  },
  resendComprovanteEstorno (payment_id) {
    return get(`/api/resend_comprovante_estorno/${payment_id}`).then(
      (response) => response.data
    )
  },
  resendComprovanteSaque (transaction_id) {
    return get(`/api/resend_comprovante_saque/${transaction_id}`).then(
      (response) => response.data
    )
  },
  balance () {
    return get('/api/saldo').then((response) => response.data)
  },
  travels (kind, limit) {
    return get('/api/travels', { kind, limit }).then(
      (response) => response.data
    )
  },
  revendedorSales (page = 1) {
    return get('/api/vendas/list_sales', { page }).then(
      (response) => response.data
    )
  },
  extratoReserva (data) {
    return get('/api/extratoreserva', { data: JSON.stringify(data) }).then(
      (response) => response.data
    )
  },
  extratoAlterarReserva (data) {
    return get('/api/extratoremarcacao', { data: JSON.stringify(data) }).then(
      (response) => response.data
    )
  },
  travel (id) {
    if (!id) {
      throw new Error(`get travel sem id (${id})`)
    }
    return get(`/api/travels/${id}`).then((response) => response.data)
  },
  noShowCupom (id) {
    if (!id) {
      throw new Error(`get travel sem id (${id})`)
    }
    return get(`/api/travels/${id}/noshow_cupom`).then(
      (response) => response.data
    )
  },
  addTravelContactPhone (id, phone) {
    return post('/api/travels/add_contact_phone', {
      id: id,
      phone: phone
    }).then((response) => response.data)
  },
  cancelTravel (id, reason = '') {
    return post(`/api/travels/${id}/cancel`, { reason }).then(
      (response) => response.data
    )
  },
  removePassenger (passenger_id) {
    return post(`/api/removepassenger/${passenger_id}`).then(
      (response) => response.data
    )
  },
  travelFeedback (id, feedback) {
    return post(`/api/travels/${id}/feedback`, {
      feedback: JSON.stringify(feedback)
    }).then((response) => response.data)
  },
  removePaymentMethod (cardId) {
    return post(`/api/remove_payment_method/${cardId}`).then(
      (response) => response.data
    )
  },
  getParcelas (amount, bin, provider) {
    return get('/api/payment_methods/get_installments', {
      amount,
      bank_identification_number: bin,
      provider
    }).then((response) => response.data)
  },
  purchase (data) {
    return post('/api/purchase', { data: JSON.stringify(data) }).then(
      (response) => response.data
    )
  },
  checkReservationStatus (reservation_id, reservation_signed) {
    return post('/api/check_reservation_status', {
      data: JSON.stringify({ reservation_id, reservation_signed })
    }).then((response) => response.data)
  },
  purchaseLinkPagamento ({ payment, code, acceptance }) {
    const params = { payment, code, acceptance }
    return post('/api/link_pagamento/purchase', {
      params: JSON.stringify(params)
    }).then((response) => response.data)
  },
  createLinkPagamento (data) {
    return post('/api/link_pagamento/create', {
      data: JSON.stringify(data)
    }).then((response) => response.data)
  },
  getReservaLinkPagamento (code) {
    return get('/api/link_pagamento/get', { code: code }).then(
      (response) => response.data
    )
  },
  getLinkComissaoWhatsApp () {
    return get('/api/vendas/get_link_comissao_whatsapp').then(
      (response) => response.data
    )
  },
  getLinkDashboardAfiliados () {
    return get('/api/vendas/get_link_dashboard_afiliados').then(
      (response) => response.data
    )
  },
  getLinkDashboardGestorPDV () {
    return get('/api/vendas/get_link_dashboard_gestor_pdv').then(
      (response) => response.data
    )
  },
  getLinkInfoBuseiro (id) {
    return get('/api/get_link_info_buseiro', { user_id: id }).then(
      (response) => response.data
    )
  },
  remarcar (data) {
    return post('/api/remarcar', { data: JSON.stringify(data) }).then(
      (response) => response.data
    )
  },
  cities (origem) {
    return get('/api/cities', { origem: origem }).then(
      (response) => response.data
    )
  },
  sendHelpMail (email, name, description, topic) {
    const params = { email, name, description, topic }
    return post('/api/help', { params: JSON.stringify(params) }).then(
      (response) => response.data
    )
  },
  sendAchadosEPerdidosMail (
    email,
    name,
    reservation_code,
    passengerLocation,
    itemDescription
  ) {
    const params = {
      email,
      name,
      reservation_code,
      passengerLocation,
      itemDescription
    }
    return post('/api/achados_e_perdidos', {
      params: JSON.stringify(params)
    }).then((response) => response.data)
  },
  search ({ origemSlug, destinoSlug, departureDate, minClasse }) {
    const params = {
      origemSlug,
      destinoSlug,
      departureDate,
      minClasse,
      v: 3,
      count_trecho_alternativo: 'f'
    }
    return get('/api/search', params).then((res) =>
      searchhelper.parseSearchResponseVuetify(res.data)
    )
  },
  searchHit (params) {
    return post('/api/search/hit', params).then((response) => response.data)
  },
  searchOrigem ({ origemSlug, departureDate, limit }) {
    if (!origemSlug) return Promise.reject(new Error('Origem é obrigatória'))
    const params = { departureDate, limit, origem: origemSlug }
    return get('/api/search/origem', params).then((response) => response.data)
  },
  searchDestino ({ destinoSlug, departureDate, limit }) {
    if (!destinoSlug) return Promise.reject(new Error('Destino é obrigatório'))
    const params = { departureDate, limit, destino: destinoSlug }
    return get('/api/search/destino', params).then((response) => response.data)
  },
  getNearbyDepartures ({ origemSlug, destinoSlug, date }) {
    const params = { origemSlug, destinoSlug, date }
    return get('/api/get_nearby_departures', params).then(
      (response) => response.data
    )
  },
  getRoutesWithGroupsList () {
    return get('/api/search/rotas_com_grupos').then((response) => response.data)
  },
  getTopRotas (destinoSlug, bairroSlug, nicknameSlug) {
    const params = { destinoSlug, bairroSlug, nicknameSlug }
    return get('/api/search/top_rotas', params).then(
      (response) => response.data
    )
  },
  getTopDestinos (slugList) {
    return get('/api/get_top_destinos', {
      slugList: JSON.stringify(slugList)
    }).then((response) => response.data)
  },
  getCidadesDestino (params) {
    return get('/api/cidades_destino', params).then((response) => response.data)
  },
  getLocalEmbarque (cidadeSlug, enderecoSlug) {
    const params = { cidadeSlug, enderecoSlug }
    return get('/api/search/local_embarque', params).then(
      (response) => response.data
    )
  },
  departureOptions (trecho, departureDate) {
    return get('/api/departure_options', { trecho, departureDate }).then(
      (response) => response.data
    )
  },
  recentSearches () {
    return get('/api/recent_searches').then((response) => response.data)
  },
  ask_refund (paymentId, value, travelId) {
    const params = { payment_id: paymentId, travel_id: travelId, value }
    return post('/api/ask_refund', params).then((response) => response.data)
  },
  invitations (paginator, filters) {
    return get('/api/invitations', {
      paginator: JSON.stringify(paginator),
      ...filters
    }).then((response) => response.data)
  },
  reclamarConvite (phone) {
    return post('/api/claiminvitation', { phone }).then(
      (response) => response.data
    )
  },
  bankAccount () {
    return get('/api/bank_account').then((response) => response.data)
  },
  solicitarSaque (value, bankAccount) {
    return post('/api/saque', {
      value,
      bank_account: JSON.stringify(bankAccount)
    }).then((response) => response.data)
  },
  confirmTransaction (transactionId, confirmationCode) {
    return post(
      `/api/confirm_transaction/${transactionId}/${confirmationCode}`
    ).then((response) => response.data)
  },
  detailsEstorno () {
    return get('/api/details_estorno').then((response) => response.data)
  },
  solicitarEstorno (ids) {
    return post('/api/estorno', { params: JSON.stringify({ ids }) }).then(
      (response) => response.data
    )
  },
  getCupomLead (data = null) {
    if (data) {
      return get('/api/get_cupom_lead', { data: JSON.stringify(data) }).then(
        (r) => r.data
      )
    }
    return get('/api/get_cupom_lead').then((r) => r.data)
  },
  creditoBlackFriday (campanha) {
    return get('/api/roleta/creditos', { campanha }).then((r) => r.data)
  },
  girarRoletaBlackFriday (campanha) {
    return post('/api/roleta/rodar', { campanha }).then((r) => r.data)
  },
  excluirPassenger (passenger_id) {
    return post('/api/passengers/remove', { passenger_id }).then((r) => r.data)
  },
  editarPassenger (passenger) {
    return post('/api/passengers/update', {
      passenger: JSON.stringify(passenger)
    }).then((r) => r.data)
  },
  adicionarPassenger (passenger) {
    return post('/api/passengers/create', {
      passenger: JSON.stringify(passenger)
    }).then((r) => r.data)
  },
  getOpcao (key, options) {
    return get('/api/mabtest/get_option', { key, options }).then((r) => r.data)
  },
  registraVisita (key, option) {
    return post('/api/mabtest/visit', { key, option }).then((r) => r.data)
  },
  registraConversao (key, option, value) {
    const params = { key, option }
    if (value) {
      params.value = value
    }
    return post('/api/mabtest/conversion', params).then((r) => r.data)
  },
  conheca (code) {
    return get('/api/conheca', { code }).then((response) => response.data)
  },
  consultaCpf (listCpfs) {
    return get('/api/vendas/consulta_cpf', {
      list_cpfs: JSON.stringify(listCpfs)
    }).then((response) => response.data)
  },
  consultaPax (searchText) {
    return get('/api/vendas/consulta_pax', { searchText: searchText }).then(
      (response) => response.data
    )
  },

  getAddonsExtrato (ids) {
    return get('/api/addons_extrato/v2', { ids }).then((response) => response.data)
  }
}
