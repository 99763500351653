import Vue from 'vue'
import passengerHelper, { PASSENGER_TYPES } from '~/helpers/passengerhelper'

const MAX_PASSENGERS_PER_RESERVATION = 15
const MAX_CACHED_PASSENGERS = 3

export const state = () => ({
  step: 1,
  promoCode: null,
  grupoIda: {},
  grupoVolta: null,
  reservationData: {
    possiblePassengers: [],
    passengers: [],
    countCadeirinhas: 0
  },
  extrato: null,
  pagamento_total: null,
  vendaItensAdicionais: null
})

// MUTATIONS
export const mutations = {
  reset (state) {
    state.grupoIda = {}
    state.grupoVolta = null
    state.reservationData = {}
  },
  clearReservationData (state) {
    state.reservationData = newReservation()
  },
  initReservation (state) {
    state.step = 1
    // state.reservationData = newReservation()
    state.grupoIda = {}
    state.grupoVolta = null
  },
  initEmptyPassengers (state) {
    state.reservationData.passengers = [{}]
  },
  initPassengers (state, passengers = []) {
    const selectedPassengers = state.reservationData.possiblePassengers.filter(p => p.selected)
    if (selectedPassengers.length === 0) {
      state.reservationData.possiblePassengers = passengers.map(p => passengerHelper.updatePassenger(p, state.grupoIda.datetime_ida)) || []
    }
  },
  setAddons (state, addons) {
    state.vendaItensAdicionais = addons
  },
  togglePassenger (state, idx) {
    const p = state.reservationData.possiblePassengers[idx]
    p.selected = !p.selected
    Vue.set(state.reservationData.possiblePassengers, idx, p)
  },
  selectPassenger (state, idx) {
    const p = state.reservationData.possiblePassengers[idx]
    p.selected = true
    Vue.set(state.reservationData.possiblePassengers, idx, p)
  },
  next (state) {
    state.step = state.step > 0 ? state.step + 1 : 0
  },
  previous (state) {
    state.step = state.step > 0 ? state.step - 1 : 0
  },
  setStep (state, step) {
    state.step = step != null ? parseInt(step) : 1
  },
  setGroupAttributes (state, { grupoIda, grupoVolta, $store }) {
    if (grupoIda) {
      Object.keys(grupoIda).forEach(attr => {
        state.grupoIda[attr] = grupoIda[attr]
      })
    }
    if (grupoVolta) {
      if (!state.grupoVolta) {
        state.grupoVolta = {}
      }
      Object.keys(grupoVolta).forEach(attr => {
        state.grupoVolta[attr] = grupoVolta[attr]
      })
    }
    if ($store) { // Injeta os dados de badge para enviar ao amplitude
      const badgeMap = $store.state.search.groupBadgeMap
      if (state.grupoIda) {
        state.grupoIda.badge = badgeMap[state.grupoIda.id]
      }
      if (state.grupoVolta) {
        state.grupoVolta.badge = badgeMap[state.grupoVolta.id]
      }
    }
  },
  setPromoCode (state, cupom) {
    if (cupom) {
      state.reservationData.promoCode = cupom
    }
  },
  editPassenger (state, { passenger, idx }) {
    Vue.set(state.reservationData.possiblePassengers, idx, passengerHelper.updatePassenger(passenger, state.grupoIda.datetime_ida))
  },
  createPassenger (state, passenger) {
    state.reservationData.possiblePassengers.push(passengerHelper.updatePassenger(passenger, state.grupoIda.datetime_ida))
  },
  removePromoCode (state) {
    state.reservationData.promoCode = null
  },
  updateReservationPassengers (state) {
    const passengers = state.reservationData.possiblePassengers.filter(p => p.selected)
    state.reservationData.passengers = passengers
    state.grupoIda.passengers = passengers
    if (state.grupoVolta) {
      state.grupoVolta.passengers = passengers
    }
  },
  resetReservationData (state) {
    state.reservationData = {}
  }
}

// ACTIONS
export const actions = {
  next ({ commit }) {
    commit('next')
  },
  previous ({ commit }) {
    commit('previous')
  }
}

// GETTERS
export const getters = {
  step (state) {
    return state.step
  },
  reservationData (state) {
    return state.reservationData
  },
  selectedPassengers (state) {
    return state.reservationData.possiblePassengers.filter(p => p.selected)
  },
  grupoIda (state) {
    return state.grupoIda
  },
  reservando (state) {
    return Object.keys(state.grupoIda).length > 0
  },
  grupoVolta (state) {
    return state.grupoVolta
  },
  passengerDataOk (state, getters) {
    const passengers = getters.selectedPassengers
    const TIPO_CRIANCA = PASSENGER_TYPES.CRIANCA.value
    return passengers.filter(p => p.type !== TIPO_CRIANCA).reduce((a, b) => (b.cpf || b.tipo_documento) && a, true)
  },
  cadeirinhasNecessarias (state, getters) {
    return getters.selectedPassengers.filter(p => p.needs_cadeirinha).length
  },
  countCriancas (state, getters) {
    return getters.selectedPassengers.filter(p => p.type === 'CRIANCA').length
  },
  isFullReason (state, getters) {
    const { grupoIda, grupoVolta } = state
    const countPassengers = getters.selectedPassengers.length
    if (countPassengers >= MAX_PASSENGERS_PER_RESERVATION) {
      return 'São permitidos no máximo 5 passageiros por reserva.'
    }
    const idaIsFull = grupoIda.vagas - countPassengers <= 0
    const voltaIsFull = grupoVolta && grupoVolta.vagas - countPassengers <= 0
    if (idaIsFull || voltaIsFull) {
      return 'Não é possível adicionar mais passageiros pois o grupo está cheio.'
    }
    return false
  },
  moreThanMaxCachedPassengers (state) {
    const possiblePassengers = state.reservationData.possiblePassengers
    return possiblePassengers.length > MAX_CACHED_PASSENGERS
  },
  maxCachedPassengers (state) {
    return MAX_CACHED_PASSENGERS
  },
  vendaItensAdicionais (state) {
    return state.vendaItensAdicionais
  }
}

// HELPERS
const newReservation = () => {
  return {
    possiblePassengers: [],
    passengers: [],
    countCadeirinhas: 0
  }
}
